var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "fill-height", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "8", md: "6" } },
            [
              _c(
                "v-alert",
                {
                  attrs: {
                    type: "error",
                    dark: "",
                    outlined: "",
                    icon: "mdi-lock",
                    border: "left",
                    color: "deep-orange accent-4",
                    elevation: "2",
                  },
                },
                [
                  _c("div", { staticClass: "headline" }, [
                    _vm._v("Access Denied"),
                  ]),
                  _c("p", [
                    _vm._v(" You do not have permission to access the "),
                    _vm.module
                      ? _c("strong", [_vm._v(_vm._s(_vm.module))])
                      : _c("span", [_vm._v("current")]),
                    _vm._v(
                      " page. Please contact your administrator if you believe this is an error. "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }